exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cancellation-js": () => import("./../../../src/pages/cancellation.js" /* webpackChunkName: "component---src-pages-cancellation-js" */),
  "component---src-pages-converters-js": () => import("./../../../src/pages/converters.js" /* webpackChunkName: "component---src-pages-converters-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-editors-js": () => import("./../../../src/pages/editors.js" /* webpackChunkName: "component---src-pages-editors-js" */),
  "component---src-pages-editors-text-editor-advance-js": () => import("./../../../src/pages/editors/text-editor-advance.js" /* webpackChunkName: "component---src-pages-editors-text-editor-advance-js" */),
  "component---src-pages-editors-text-editor-standard-js": () => import("./../../../src/pages/editors/text-editor-standard.js" /* webpackChunkName: "component---src-pages-editors-text-editor-standard-js" */),
  "component---src-pages-image-to-text-js": () => import("./../../../src/pages/image-to-text.js" /* webpackChunkName: "component---src-pages-image-to-text-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-speech-to-text-js": () => import("./../../../src/pages/speech-to-text.js" /* webpackChunkName: "component---src-pages-speech-to-text-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-converter-js": () => import("./../../../src/templates/converter.js" /* webpackChunkName: "component---src-templates-converter-js" */),
  "component---src-templates-keyboard-layout-js": () => import("./../../../src/templates/keyboard-layout.js" /* webpackChunkName: "component---src-templates-keyboard-layout-js" */),
  "component---src-templates-keyboard-layouts-js": () => import("./../../../src/templates/keyboard-layouts.js" /* webpackChunkName: "component---src-templates-keyboard-layouts-js" */),
  "component---src-templates-legacy-font-js": () => import("./../../../src/templates/legacy-font.js" /* webpackChunkName: "component---src-templates-legacy-font-js" */),
  "component---src-templates-legacy-fonts-js": () => import("./../../../src/templates/legacy-fonts.js" /* webpackChunkName: "component---src-templates-legacy-fonts-js" */),
  "component---src-templates-unicode-font-js": () => import("./../../../src/templates/unicode-font.js" /* webpackChunkName: "component---src-templates-unicode-font-js" */),
  "component---src-templates-unicode-fonts-js": () => import("./../../../src/templates/unicode-fonts.js" /* webpackChunkName: "component---src-templates-unicode-fonts-js" */)
}

